export default [
  {
    title: 'Beranda',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'all',
  },
  {
    title: 'Kelola Pesanan',
    icon: 'ShoppingCartIcon',
    resource: 'order',
    route: 'orders',
    action: 'all',
  },
  {
    title: 'Kelola Produk',
    icon: 'BoxIcon',
    resource: 'product',
    action: 'all',
    children: [
      {
        title: 'Produk Master',
        resource: 'product',
        route: 'productsMaster',
        action: 'all',
      },
      {
        title: 'Produk Marketplace',
        resource: 'product',
        action: 'all',
      },
    ],
  },
  {
    title: 'Kelola Laporan',
    icon: 'BarChart2Icon',
    resource: 'report',
    action: 'all',
    children: [
      {
        title: 'Laporan Penjualan',
        resource: 'report',
        action: 'all',
      },
      {
        title: 'Laporan Stok',
        resource: 'report',
        action: 'all',
      },
    ],
  },
  {
    title: 'Laporan Langganan',
    icon: 'CloudIcon',
    resource: 'report',
    action: 'all',
  },
  {
    title: 'Langganan',
    icon: 'ShoppingBagIcon',
    resource: 'subscribe',
    action: 'all',
    children: [
      {
        title: 'Pilih Langganan',
        route: 'subscription',
        resource: 'subscribe',
        action: 'all',
      },
      {
        title: 'History Berlangganan',
        resource: 'subscribe',
        action: 'all',
      },
    ],
  },
  {
    title: 'Integrasi Toko',
    icon: 'LinkIcon',
    route: 'integration',
    resource: 'settings',
    action: 'all',
  },
]
